.progress-chart{
    height: 20px;
    width: 100%;
    display: flex;
    position:relative;
    top:50%;
}
.progress-chart-green{
    background: green;
    height: 100%;
}
.progress-chart-grey{
    background: grey;
    height: 100%;
}
.progress-scale{
    display:flex;
    justify-content: space-between;
}