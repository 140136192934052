.card-chart {
  color: $text-card;
  display: flex;
  .Progress-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-header {
    h4 {
      font-weight: 550;
    }
    .card-title {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .card-category {
      margin-bottom: 5px;
    }
  }
  .card-body {
    h1 {
      align-self: center;
      font-size: 3em;
      font-weight: 550;
    }
  }
  .card-body b {
    color: $text-card-green;
  }

  .table {
    margin-bottom: 0;

    td {
      border-top: none;
      border-bottom: 1px solid #e9ecef;
    }
  }

  .card-progress {
    margin-top: 30px;
  }

  .chart-area {
    height: 210px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
  }
  .card-footer {
    margin-top: 15px;

    .stats {
      color: $dark-gray;
    }
  }

  .dropdown {
    position: absolute;
    right: 20px;
    top: 20px;

    .btn {
      margin: 0;
    }
  }
}

#ph-center {
  margin-top: -90px;
  font-size: 3.5rem;
}

#thermometer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  h1 {
    margin-right: -9%;
    margin-left: 5%;
  }
}

.card-text-center {
  display: flex;
  align-content: center;
  justify-content: center;
  h1 {
    align-self: center;
  }
}

.side-chart {
  height: 97.2%;
}
.Canvas-scale {
  display: flex;
  justify-content: space-between;
}
.Thermometer-scale {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 340px;
  margin-left: -40%;
  margin-bottom: 60px;
}
.medias-title {
  display: flex;
  justify-content: space-between;

  .card-subtitle {
    margin: 5px 0;
  }
  input {
    border: 0;
    background: #f2f2f2;
    margin-left: 4px;
  }
}

.history-card {
  h4,
  b {
    color: #263238;
  }
}
#center-donut {
  margin-top: -95px;
  font-size: 1.5rem;
}
