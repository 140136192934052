/*!

 =========================================================
 * Now UI Dashboard React - v1.4.0 based on Now UI Dashboard - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */


@import 'now-ui-dashboard/variables';
@import 'now-ui-dashboard/mixins';

// Plugins CSS
@import "now-ui-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "now-ui-dashboard/plugins/plugin-perfect-scrollbar";

// Core CSS
@import "now-ui-dashboard/typography";
@import "now-ui-dashboard/misc";

// components
@import "now-ui-dashboard/navbar";
@import "now-ui-dashboard/page-header";
@import "now-ui-dashboard/images";
@import "now-ui-dashboard/sidebar-and-main-panel";
@import "now-ui-dashboard/footers";

// cards
@import "now-ui-dashboard/cards";
@import "now-ui-dashboard/cards/card-plain";
@import "now-ui-dashboard/cards/card-chart";
@import "now-ui-dashboard/cards/card-user";
@import "now-ui-dashboard/cards/card-map";

@import "now-ui-dashboard/responsive";

// react
@import "now-ui-dashboard/react/react-differences";

@import 'now-ui-dashboard/progress-bar';
@import 'now-ui-dashboard/thermometer';
@import 'now-ui-dashboard/side-menu';

@import 'now-ui-dashboard/login-page';
