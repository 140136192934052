.thermometer {
    position: relative;
    left: 50%;
}
.thermometer-cilinder {
    width: 20px;
    height: 150px;
    background: green;
    margin-bottom: -2px;
    margin-left: 15px;
}
.thermometer-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: green;
}
