.login-page {
    min-width: 400px;
    height: 100vh;
    display: flex;
    font-family: "IBM Plex Sans", sans-serif;
    .login-info {
        background: $text-card-green;
        width: 60vw;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 300px;
    }
    .login-logo {
        background: $logo-blue;
        width: 40vw;
        height: 100vh;
        display: flex;
        padding: 0 1rem;
        min-width: 300px;
    }

    @media screen and (max-width: 600px) {
        display: unset !important;
        .login-info {
            width: 100vw;
            height: 80%;
        }
        .login-logo {
            width: 100vw;
            height: 20%;
        }
    }
}
.login-input {
    display: flex;
    flex-direction: column;
    width: 60%;
    color: $logo-blue;
    h1 {
        font-weight: bold;
    }
    button {
        width: 100px;
        align-self: end;
        border: transparent;
        background-color: $logo-blue;
        color: $input-grey;
        border-radius: 0.5rem;
    }
    input {
        margin: 0.5rem 0;
        background: $input-grey;
        border: transparent;
        border-radius: 0.2rem;
    }
}
